<template>
	<div>
		<div class="box">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">{{ $t('invoice.stats_invoices_bons') }}</h3>
                </div>
                <div class="col-auto">
                    <b-button v-b-toggle.invoiceStat variant="primary" class="mb-0">{{ $t('invoice.see_stats') }} <font-awesome-icon :icon="['fal', 'angle-down']" /><font-awesome-icon :icon="['fal', 'angle-up']" /></b-button>
                </div>
            </div>
            <b-collapse v-if="stats_loaded" id="invoiceStat">
                <div class="row pt-4">
                   <b-table striped hover :items="stat" :fields="fields_stat"></b-table>
                </div>
            </b-collapse>
            <div v-else>
                <LoadingSpinner />
            </div>
        </div>
		<div class="row">
		    <div class="col-auto ml-auto d-flex">
		        <a href="" v-if="options.type == 'Contract'" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractInvoices(options.id)">
		            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.factures') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
		        </a>
		    </div>
		</div>
		<div class="box">
			<CustomTable
				id_table="bons"
				:items="bons"
				:busy.sync="table_busy"
				primaryKey="invoicedetails_id"
				:hide_if_empty="true"
				:hrefsRoutes="config_table_hrefs"
				:rawColumns="rawColumnNames"
				:externSlotColumns="extern_slot_columns"
				ref="bonTable"
			>
                <template v-slot:[`custom-slot-cell(invoicedetails_tiershorsepartpart)`]="{ data }">
					{{ data.invoicedetails_tiershorsepartpart }}%
				</template>
                <template v-slot:[`custom-slot-cell(invoicedetails_description)`]="{ data }">
					<span v-html="cleanHTML(data.invoicedetails_description)"></span>
				</template>
            </CustomTable>
		</div>

		<template v-if="has_doublons">
			<div class="row">
			    <div class="col">
					<h2>{{ $t("invoice.titre_bons_doublons") }}</h2>
			    </div>
			    <div class="col-auto ml-auto d-flex">
			        <a href="" v-if="options.type == 'Contract'" class="btn ml-3 mb-4 btn-secondary" @click.prevent="setupContractInvoices(options.id)">
			            <i><font-awesome-icon :icon="['far', 'file-pdf']" /></i> {{ $t('monte.factures') }} <i><font-awesome-icon :icon="['fas', 'eye']" /></i>
			        </a>
			    </div>
			</div>
			<div class="box">
				<CustomTable
					id_table="bons_doublons"
					:items="bons_doublons"
					:busy.sync="table_busy"
					primaryKey="invoicedetails_id"
					:hide_if_empty="true"
					:hrefsRoutes="config_table_hrefs"
					:rawColumns="rawColumnNames"
					ref="bonDoublonsTable"
				/>
			</div>
		</template>

		<ModalBons :bons="bons" ref="modal" :version="3"/>

		<b-modal ref="modalLigneVierge" no-close-on-backdrop size="xl" hide-footer>
			<template v-slot:modal-title>

				<template v-if="ajouter">
					{{ $t("action.ajout_ligne_vierge") }}
				</template>

				<template v-if="modifier">
					{{ $t("action.modifier_ligne_vierge") }}
				</template>
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<LigneVierge ref="ligne" :invoicedetails_id="invoicedetails_id" :bons="bons" :processing.sync="processing" :preselect_infos="preselect_infos" :ready.sync="ready" :options="options"></LigneVierge>

				<div class="col-8 m-auto">
					<template v-if="ready && invoicedetails_id">
						<b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
						<b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier_et_nouveau") }}</b-button>
					</template>
					<template v-else-if="ready">
						<b-button block pill variant="primary" @click="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
						<b-button block pill variant="primary" @click="onSubmit(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter_et_nouveau") }}</b-button>
					</template>
				</div>
			</div>
		</b-modal>
		<b-modal ref="modalCancel" hide-footer>
			<template v-slot:modal-title>
				{{ $t("modal.invoice.bons.suppression") }}
			</template>

			<p class="text-center">{{ $t("modal.invoice.bons.confirmation_suppression") }}</p>

			<WarnAlert v-if="message_partiel" messageI18n="invoice.baf_partiel_warning" />

			<div class="row">
				<div class="col-12 d-flex justify-content-center mb-2">
					<b-button v-if="message_partiel && is_cancelation" variant="primary" @click="onCancelAll(true)">{{ $t("action.tout_repasser_a_valider") }}</b-button>
					<b-button v-if="message_partiel"  variant="primary" class="mx-2" @click="onCancelAll">{{ $t("action.tout_abandonner") }}</b-button>
				</div>
				<div class="col-12 d-flex justify-content-center">
					<b-button variant="primary" @click="onCancel(true)" v-if="is_cancelation && !message_partiel">{{ $t("action.repasser_a_valider") }}</b-button>
					<b-button variant="primary" class="mx-2" @click="onCancel">{{ $t("action.abandonner") }}</b-button>
					<b-button variant="secondary" @click="$refs.modalCancel.hide()">{{ $t("global.close") }}</b-button>
				</div>
			</div>
		</b-modal>
		<b-modal ref="modalChangeTiers" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.change_tiers") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">

				<form @submit.prevent="checkFormChangeTiers" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							<div class="mb-2">
								<label class="mb-1" for="tiers_id">{{ $t('tiers.tiers') }} *</label>
								<e-select
									v-model="change_tiers"
									track-by="id"
									label="rs"
									:placeholder="labelTitleTiers"
									:selectedLabel="selectedLabel"
									:options="tiers"
									:searchable="true"
									:allow-empty="false"
									:loading="isLoadingTiers"
									:show-labels="false"
									:class="{ 'is-invalid': errors && errors.indexOf('Erreur no tiers') > -1 }"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.rs }}</template>
									<template slot="noOptions">{{ $t('global.list_empty') }}</template>
								</e-select>

							</div>
						</div>
					</div>
				</form>

				<div class="col-8 m-auto">
					<b-button v-if="ready && invoicedetails_id" block pill variant="primary" @click="onSubmitChangeTiers"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.modifier") }}</b-button>
				</div>
			</div>
		</b-modal>

		<b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
				<b-progress :max="preview_invoicedetails_ids.length" height="2rem" show-progress>
					<b-progress-bar :value="progress_preview">
                		<div class="innerProgress w-100 text-center">{{ $t('global.chargement') }} {{((progress_preview / preview_invoicedetails_ids.length) * 100).toFixed(0)}}%</div>
					</b-progress-bar>
				</b-progress>
            </div>
        </b-modal>

		<b-modal size="xl" ref="modelInvoiceConfirmation0" hide-footer @hidden="base64 = null">
           
        </b-modal>

        <b-modal ref="tiers_vat_modal" hide-footer>
            <template v-slot:modal-title>
                {{ $t('tiers.num_tva') }}
            </template>

            <div class="row justify-content-around">
            	<div class="col-12">
	                <b-alert show variant="warning" class="mt-1">
	                    {{ $t('invoice.message_article_vat_tiers') }}
	                </b-alert>
            	</div>

            	<div class="col-12 mt-2" v-for="(tiers, index) in tiers_vat_selected" :key="tiers.tiers_id">
            		<label>{{ tiers.tiers_rs }}</label>
                    <input type="text" class="form-control" v-model="tiers.tiers_vat_number" @keyup="checkVATFormat(index)" :class="{ 'is-invalid': tiers.error}">
                    <b-alert v-if="!tiers.vat_valid" show variant="warning" class="mt-2">
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
                        {{ $t('tiers.invalid_format_vat') }}
                    </b-alert>
            	</div>
            	<div class="text-center mt-2">
					<b-button variant="primary" :disabled="!vat_savable" @click="saveVATNumber"><font-awesome-icon v-if="processing_tva" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /> {{ $t("global.enregistrer") }}</b-button>
					<b-button variant="secondary" class="ml-2" @click="addInvoiceForm(invoicedetails_ids_selected)"><font-awesome-icon :icon="['fal', 'long-arrow-right']" /> {{ $t("global.continue_anyway") }}</b-button>
            	</div>
            </div>
        </b-modal>

        <ModalSelectModelPreviewBAF ref="modelSelectPreview" :models="model_by_author" :callback="previewInvoiceDetail" />
        <ModalAvoirCC ref="modalAvoirCC" :submit="submitModalCC" :callback="submitModalCC"/>
        <ModalEditHTLot ref="modal_lot_ht" @submit="loadBons" />
	</div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus'

	import Common from '@/assets/js/common.js'
	import TableAction from "@/mixins/TableAction.js"
	import Invoice from "@/mixins/Invoice.js"
	import Tiers from "@/mixins/Tiers.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Model from "@/mixins/Model.js"
	import Accounting from "@/mixins/Accounting.js"
	import Contract from "@/mixins/Contract.js"

	import ShutterContract from "@/mixins/shutters-manager/Contract.js"
	import Shutter from '@/mixins/Shutter.js'
	import Tools from '@/mixins/Tools.js'

	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'
	import _uniq from 'lodash/uniq'
	import _groupBy from 'lodash/groupBy'
	import xss from 'xss'
	import ModalBons from '../Modals/ModalBons.vue'

	export default {
		name: "Bonsv3",
		mixins: [Config, TableAction, Invoice, Navigation, Tiers, ShutterContract, Shutter, Model, Accounting, Contract, Tools],
		props: {
			options: {
				type: Object,
				default: () => ({
					type: 'Invoice',
					id: null,
				})
			}
		},
		data () {
			return {
				bons: null,
				bons_doublons: null,
				table_busy: true,
				first_invoice: false,
				modal_loaded: false,
				invoicedetails_id: null,
				date: new Date(),
				today: new Date(),
				is_cancelation: true,
				form_message: '',
				params_to_send : [],
				errors : [],
				config_duedate : [],
				amount : {},
				processing: false,
				ready: false,
				entity_id: null,
				horse_id: null,
				tiers_id: null,
				base64: null,
				ajouter: true,
				modifier: false,
				many_duedate: false,
				has_doublons: false,
				copy_duedate: null,
				dedoublonner: false,
				cancel_invoicedetails_ids: null,
				preselect_infos: {},
				change_tiers: null,
				renew: {
					"code": 'null',
					"label": this.getTrad("invoice.renew.no")
				},
				renew_options: [
					{
						"code": 'null',
						"label": this.getTrad("invoice.renew.no")
					},
					{
						"code": 'mensuel',
						"label": this.getTrad("invoice.renew.mensuel")
					},
					{
						"code": 'annuel',
						"label": this.getTrad("invoice.renew.annuel")
					},
				],
				model: [],
				tiers: [],
				isLoadingTiers: false,
				labelTitleTiers: this.getTrad("tiers.rechercher"),
				extern_slot_columns: ['invoicedetails_tiershorsepartpart','invoicedetails_description'],
				selectedLabel: this.getTrad("global.selected_label"),
				cache: {
					model: []
				},
				recalcul_duedate: true,
				rawColumnNames: ['horse.horse_nom','invoicedetails_ht'],
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'horseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					},
					'tiers.tiers_rs': {
						routeUniqueName: 'tiersFiche',
						routeName: 'tiersFiche',
						params: {
							tiers_id: 'tiers.tiers_id'
						}
					}
				},
				events_tab: {
					'TableAction::goToAddInvoice': (params) => {
						this.checkVATNumber(params.invoicedetails_id)
					},
					'TableAction::goToAddInvoiceDetail': (params) => {
						this.addInvoiceDetailForm(params.invoicedetails_id)
					},
					'TableAction::goToCancelInvoiceDetail': (params) => {
						this.is_cancelation = true
						this.cancel_invoicedetails_ids = params.invoicedetails_id
						this.dedoublonner = false
						this.checkDetailsGroup();
						this.$refs.modalCancel.show()
						// this.cancelInvoiceDetail(params.invoicedetails_id)
					},
					'TableAction::goToDedoublonnerInvoiceDetail': (params) => {
						this.is_cancelation = false
						this.cancel_invoicedetails_ids = params.invoicedetails_id
						this.dedoublonner = true
						this.$refs.modalCancel.show()
						// this.cancelInvoiceDetail(params.invoicedetails_id, true)
					},
					'TableAction::goToPreviewInvoice': (params) => {
						this.setPreviewInvoiceDetail(params.invoicedetails_id)
					},
					'TableAction::goToPreviewInvoiceWithModel': (params) => {
						this.openModalSelectPreview(params.invoicedetails_id)
					},
					'TableAction::goToChangeTiersBaf': (params) => {
						this.changeTiersBaf(params)
					},
					'TableAction::goToReorganizeInvoiceDetails': (params) => {
						let bons = this.bons.filter(bon => params.indexOf(bon.invoicedetails_id) > -1 )
						let grouped_bons = bons.map((elem) => {
							return elem.invoicedetails_author + "_" + elem.invoicedetails_tiers
						})
						grouped_bons = _uniq(grouped_bons)
						if(grouped_bons.length > 1)
						{
							this.failureToast('toast.reorganize_only_one_tiers')
							return false
						}

						this.$router.push({ name: 'invoiceDetailOrderv2', params: {invoicedetails_ids: params.join(',')}})
					},
					'TableAction::goToCopyInvoiceDetail': (params) => {
						this.copyInvoiceDetail(params)
					},
					'TableAction::goToRecheckVat': (params) => {
						this.recheckVat(params)
					},
					'TableAction::goToEditHT': this.openModalEditHT
				},
				stats_loaded: true,
                stat: [],
                fields_stat: [
					{key: 'tiers', label: this.getTrad('monte.reservation.client'), sortable: true},
                    {key: 'total_ht', label: this.getTrad('invoice.stat_total_ht'), sortable: true},
                ],
                preview_invoicedetails_ids: [],
                model_by_author: [],
                message_partiel: false,
                tiers_vat_selected: [],
                processing_tva: false,
                vat_savable: true,
                invoicedetails_ids_selected: [],
                progress_id: '',
                progress_preview: 0
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
                this.loadBons()
			},
			async loadBons() {
				this.table_busy = true
				this.has_doublons = false
                const bons = await this.loadAllBonsv2(this.options)
                this.bons = await this.formatBons(bons)

                const bons_doublons = await this.loadAllBonsv2({
                	type: 'Doublons',
                	id: null
                })
                this.bons_doublons = await this.formatBons(bons_doublons)
                if(this.bons_doublons.length > 0)
                {
                	this.has_doublons = true
                }

				this.loadStats()
                
                this.table_busy = false
            },
            async formatBons(bons) {
				if(!bons) {
					return []
				}

				for (var i = 0; i < bons.length; i++) {

					if(bons[i].horse != null)
					{
						bons[i].href = "horseFiche"
						bons[i].params = { horse_id: bons[i].horse.horse_id }
					}

					// let dates = []
					let label = bons[i].invoicedetails_label
					// bons[i].invoicedetails_label.split(' - ').forEach(el => {
					// 	if(new Date(el) != 'Invalid Date') {
					// 		dates.push(el)
					// 	}
					// })

					// if(dates.length == 2 && bons[i].invoicedetails_label.split(' - ').length == dates.length) {
					// 	label = dates.join(' - ')
					// }
					// else if(dates.length == 1 && bons[i].invoicedetails_label.split(' - ').length == dates.length) {
					// 	label = dates[0]
					// }

					if(bons[i].invoicedetails_horsepensionperiode != null)
					{
						bons[i].articles.articles_label += " - " + label
					}

					bons[i].group_by_author_tiers = bons[i].author.tiers_rs + " / " + bons[i].tiers.tiers_rs + " (" + this.getTrad("compta.form." + bons[i].vat_code_tiers.toLowerCase()) + ")"
					bons[i].customClasses={
                        invoicedetails_ht:"text-right"
					}

					if(bons[i].invoicedetails_horsepensionperiode) {
						bons[i].group_id = 'pension_'+bons[i].invoicedetails_horsepensionperiode
					}
					else if(bons[i].invoicedetails_actes) {
						bons[i].group_id = 'acte_'+bons[i].invoicedetails_actes
					}
					else {
						bons[i].group_id = 'autre_'+bons[i].invoicedetails_id
					}

					bons[i].invoicedetails_htunit = bons[i].invoicedetails_ht_initial
				}

				return bons
			},
			async checkVATNumber(invoicedetails_ids) {
				const countries = Object.keys(this.getCountryVatRegex())
				this.invoicedetails_ids_selected = invoicedetails_ids
				const details_selected = this.bons.filter(bons => invoicedetails_ids.includes(bons.invoicedetails_id))

				const details_country = details_selected.filter(details => {
					// si pays non concerné par normes TVA
					if (!countries.includes(details.tiers.tiers_country.toString())){
						return false
					}
					// si TVA invalide
					if(!details.tiers.tiers_vatcheck && details.tiers.tiers_vat_number)
						return true
					// Si  compta (UE ou UK) ET client  (UE ou UK) ET  pays différents ET une ligne à 0% ET numéro TVA client absent
					if(details.vat.vat_id == 1 && countries.includes(details.author.tiers_country.toString()) && countries.includes(details.tiers.tiers_country.toString()) && details.author.tiers_country !== details.tiers.tiers_country && details.tiers.tiers_vat_number == '')
						return true
					return false
				})
				if(details_country.length == 0) {
					this.addInvoiceForm(invoicedetails_ids)
					return false
				}

				const details_tiers = _groupBy(details_selected, 'invoicedetails_tiers')
				let tiers = []
				for(let i in details_tiers) {
					tiers.push({...details_tiers[i][0].tiers, error: false, vat_valid: this.checkVAT(details_tiers[i][0].tiers.tiers_vat_number, details_tiers[i][0].tiers.tiers_country)})
				}
				this.tiers_vat_selected = tiers
				this.$refs['tiers_vat_modal'].show()

	            this.vat_savable = this.tiers_vat_selected.filter(tiers => !tiers.vat_valid).length == 0

			},
			async checkVATFormat(index) {
	            this.tiers_vat_selected[index].tiers_vat_number = this.tiers_vat_selected[index].tiers_vat_number.replace(/\W/g, '')
	            this.tiers_vat_selected[index].vat_valid = this.checkVAT(this.tiers_vat_selected[index].tiers_vat_number, this.tiers_vat_selected[index].tiers_country)
	            this.tiers_vat_selected[index].error = false

	            this.vat_savable = this.tiers_vat_selected.filter(tiers => !tiers.vat_valid).length == 0
			},
			async saveVATNumber() {
				this.processing_tva = true
				for(let i in this.tiers_vat_selected) {
					if(this.tiers_vat_selected[i].tiers_vat_number) {
						this.tiers_vat_selected[i].error = await this.checkTvaValidOnline(this.tiers_vat_selected[i].tiers_vat_number, this.tiers_vat_selected[i].tiers_country) != 1
						if(!this.tiers_vat_selected[i].error) {
							await this.editVatNumber(this.tiers_vat_selected[i].tiers_id, this.tiers_vat_selected[i].tiers_vat_number, this.tiers_vat_selected[i].tiers_country)
						}
					}
					else {
						await this.editVatNumber(this.tiers_vat_selected[i].tiers_id, this.tiers_vat_selected[i].tiers_vat_number, this.tiers_vat_selected[i].tiers_country)
					}
				}
				let errors = this.tiers_vat_selected.filter(tiers => tiers.error)

				if(errors.length > 0) {
					this.failureToast('toast.vat_invalide')
					this.processing_tva = false
					return false
				}

				this.$sync.force(true)
				this.processing_tva = false

				this.addInvoiceForm(this.invoicedetails_ids_selected)
			},
			async addInvoiceForm(invoicedetails_ids)
			{
				await this.$refs['modal'].openModal(invoicedetails_ids)
			},

			addInvoiceDetailForm(invoicedetails_id) {
				this.modal_loaded = false
				this.preselect_infos = {}
				if(invoicedetails_id == "")
				{
					this.modifier = false
					this.ajouter = true
				}
				else
				{
					this.modifier = true
					this.ajouter = false
				}
				this.invoicedetails_id = invoicedetails_id

				this.$refs["modalLigneVierge"].show()

				this.modal_loaded = true
			},

			copyInvoiceDetail(invoicedetails) {

				this.modal_loaded = false

				this.modifier = false
				this.ajouter = true
				this.preselect_infos = {
					entity: invoicedetails.author,
					tiers: invoicedetails.tiers,
					horse: invoicedetails.horse,
					article: invoicedetails.articles,
					invoice_details: invoicedetails,
				}

				this.$refs["modalLigneVierge"].show()

				this.modal_loaded = true
			},

			async openModalSelectPreview(invoicedetails_ids) {
				this.preview_invoicedetails_ids = invoicedetails_ids.split(",")

				const bons_selected = this.bons.filter(bon => this.preview_invoicedetails_ids.includes(bon.invoicedetails_id.toString()))
				const bons_grouped =  _groupBy(bons_selected, 'invoicedetails_author')

				const model_by_author = []
				for (let author in bons_grouped) {
					let accounting_plan = await this.loadAccountingPlanByEntity(author)

					let models = await this.loadAllModel(accounting_plan.accountingplan_id, ['accounting_plan'])
					models = models.filter(model => (model.type.modeltype_template === 'facture'))

					model_by_author.push({
						accounting_plan,
						models
					})
				}
				this.model_by_author = model_by_author

				// si je n'ai qu'une entité sélectionnée et un seul modèle je lance directement la prévisualisation
				// sinon j'ouvre la modale de sélection
				if(this.model_by_author.length == 1 && this.model_by_author[0].models.length == 1) {
					const model_author = {}
					model_author[this.model_by_author[0].accounting_plan.accountingplan_tiers] = this.model_by_author[0].models
					this.previewInvoiceDetail(model_author)
				}
				else {
					this.$refs.modelSelectPreview.openModal()
				}

			},

			setPreviewInvoiceDetail(invoicedetails_ids) {
				this.preview_invoicedetails_ids = invoicedetails_ids.split(",")
				this.previewInvoiceDetail()
			},

			async previewInvoiceDetail(models = null) {
				this.modal_loaded = false

				if(models && Object.keys(models).length == 1) {
					this.$refs.modelSelectPreview.closeModal()
				}

				const splited_invoice_details = this.preview_invoicedetails_ids
				const all_bons = this.bons
				const infos = []
				const tiers = []
				const authors = []
				for (var i = 0; i < splited_invoice_details.length; i++) {

					const bons_selected = this.bons.filter(all_bons => all_bons.invoicedetails_id == splited_invoice_details[i])[0]

					if(authors.indexOf(bons_selected.invoicedetails_author) === -1 || tiers.indexOf(bons_selected.invoicedetails_tiers) === -1)
					{
						const bon = {
							"author_id": bons_selected.invoicedetails_author,
							"tiers_id": bons_selected.invoicedetails_tiers
						}

						if(models) {
							bon["model"] = {model_id: models[bons_selected.invoicedetails_author].model_id}
						}

						infos.push(bon)

						tiers.push(bons_selected.invoicedetails_tiers)
						authors.push(bons_selected.invoicedetails_author)
					}
				}


				this.progress_id = Math.random().toString(16).slice(2)
				this.progress_preview = 0

				let params_to_send = {
					"invoices": infos,
					"invoicedetails_ids": this.preview_invoicedetails_ids.join(','),
					"progress_id": this.progress_id
				}

				let download = true 
				if(infos.length == 1) {
					download = false
				}
				this.$refs.modelPreview.show()

				this.previewInvoice(params_to_send, download).then(res => {
					if(infos.length == 1) {
						this.base64 = res
					}
					else {
						this.$refs.modelPreview.hide()
					}
					if(res) {
						EventBus.$emit('TableAction::stopSpin')
					}
					this.progress_id = null
				})
				this.updateProgressPreview()

				this.$refs.modelSelectPreview.closeModal()
				this.modal_loaded = true
			},

			async updateProgressPreview() {
				if(!this.progress_id) return false
					
				this.progress_preview = await this.getProgressPreviewInvoice(this.progress_id)
				if(this.progress_preview < this.preview_invoicedetails_ids.length) {
					setTimeout(this.updateProgressPreview, 1500);
				}
			},

			submitModalCC() {
				this.$router.push({name: 'invoiceList'})
			},

			async onCancel(go_valider) {
				this.$refs.modalCancel.hide();
				if(go_valider === true) {
					await this.cancelInvoiceDetail(this.cancel_invoicedetails_ids, this.dedoublonner, true)
				}
				else {
					await this.cancelInvoiceDetail(this.cancel_invoicedetails_ids, this.dedoublonner, false)
				}
			},

			async onCancelAll(go_valider) {
				this.$refs.modalCancel.hide();

				let details_cancel = this.cancel_invoicedetails_ids.split(',')
				let details = this.bons.filter(b => details_cancel.includes(b.invoicedetails_id.toString()))
				let group = details.map(d => d.group_id)

				// je récupère les lignes appartenant aux groupes de mes lignes sélectionnées
				for(let i in group) {
					if(group[i].includes('pension_') || group[i].includes('acte_')) {
						let details_code = this.bons.filter(b => group[i] == b.group_id && !details_cancel.includes(b.invoicedetails_id.toString()))
						details_code = details_code.map(d => d.invoicedetails_id.toString())
						details_code.forEach(d => {
							details_cancel.push(d)
						})
					}
				}

				if(go_valider === true) {
					await this.cancelInvoiceDetail(details_cancel.join(','), this.dedoublonner, true)
				}
				else {
					await this.cancelInvoiceDetail(details_cancel.join(','), this.dedoublonner, false)
				}
			},

			async cancelInvoiceDetail(invoicedetails_ids, dedoublonner, send_a_valider){
				let params_to_send = {
					invoicedetails_ids: invoicedetails_ids,
					send_a_valider: send_a_valider
				}

				let result = null
				if(dedoublonner != undefined && dedoublonner === true) {
					result = await this.dedoublonnerInvoiceDetails(params_to_send)
				}
				else{
					result = await this.deleteInvoiceDetails(params_to_send)
				}


				if(result != null) {
					this.$refs.bonTable.resetCachedItems()

					if(this.$refs.bonDoublonsTable) {
						this.$refs.bonDoublonsTable.resetCachedItems()
					}

					this.successToast()
					this.loadBons()

					if(this.options.type == "Contract"){
						//Je force la synchro pour reload la table contract_avenant_article et ensuite je vais recharger les "a valider"
						EventBus.$emit('TableAction::waitingTableAValider', true)
						await this.$sync.force(true, true)
	                    ContractCleaner.inst().onMutation([this.options.id], ['articles'])
	                    this.updateContract([this.options.id])
	                    EventBus.$emit('TableAvenantArticle::ReloadArticle', true)
						EventBus.$emit('TableAction::reloadAValider', true)
					}
				}
			},

			async recheckVat(params){
				let result = await this.getRecheckVat(params.invoicedetails_id.split(','))

				if(result != null) {
					this.$refs.bonTable.resetCachedItems()
					this.successToast()
					this.loadBons()
				}
			},

			async onSubmit(open_with_copy = false) {
				this.processing = true
				let invoice_details = await this.$refs.ligne.checkForm()

				if(invoice_details !== false)
				{
					this.$refs["modalLigneVierge"].hide()
					this.successToast()
					this.loadBons()
					if(this.options.type == "Contract") {
                    	await this.$sync.force(true)
	                	ContractCleaner.inst().onMutation([this.options.id], ['articles'])
	                	this.updateContract([this.options.id])
					}

					if(open_with_copy === true) {
						this.invoicedetails_id = null
						//Dans le cas d'ajout de ligne, on me retourne un tableau de ligne, du coup je prend la 1ere
						if(Array.isArray(invoice_details.retour)) {
							this.copyInvoiceDetail(invoice_details.retour[0])
						}
						else {
							this.copyInvoiceDetail(invoice_details.retour)
						}
					}
				}
				this.processing = false
				this.ready = true
			},

			pension_checkall(item){
				if(item.length)
				{
					if(item[0].invoicedetails_horsepensionperiode != null)
					{
						//Je vais regarder toutes les pensions id qui vont ensembles sur le même cheval et même tiers pour toutes les facturer en même temps
						let invoicedetails_horsepensionperiode = item[0].invoicedetails_horsepensionperiode
						
						let all_same_pensions = this.bons.filter(pension => pension.invoicedetails_horsepensionperiode == invoicedetails_horsepensionperiode)

						this.$refs.bonTable.manual_check(all_same_pensions)
					}
				}
				
			},

			pension_decheckall(item){
				if(item.length)
				{
					if(item[0].invoicedetails_horsepensionperiode != null)
					{
						let invoicedetails_horsepensionperiode = item[0].invoicedetails_horsepensionperiode

						let all_same_pensions = this.bons.filter(pension => pension.invoicedetails_horsepensionperiode == invoicedetails_horsepensionperiode) 
						this.$refs.bonTable.manual_decheck(all_same_pensions)
					}
				}
				
			},

			async changeTiersBaf(invoicedetails_ids){
				this.$refs.modalChangeTiers.show()
				this.ready = false
				this.modal_loaded = false
				this.isLoadingTiers = true
				this.invoicedetails_id = invoicedetails_ids

				let authors = []
				for (var i = 0; i < this.bons.length; i++) {
					if(invoicedetails_ids.includes(this.bons[i].invoicedetails_id)) {
						authors.push(this.bons[i].invoicedetails_author)
					}
				}

				let tiers = await this.loadTiers()
				this.tiers = []
                for (let i = 0; i < tiers.length; i++) {
					if(!authors.includes(tiers[i].tiers_id)) {
						this.tiers.push({
							id: tiers[i].tiers_id,
							rs: tiers[i].tiers_formatted,
						})
					}
				}

				this.ready = true
				this.modal_loaded = true
				this.isLoadingTiers = false
			},

			async onSubmitChangeTiers(){

				if(!this.processing)
				{
					this.processing = true
					this.errors = []
					if(!this.invoicedetails_id)
					{
						this.errors.push("lignes")
					}

					if(!this.change_tiers)
					{
						this.errors.push("Erreur no tiers")
					}
					if(this.errors.length > 0)
					{
						this.processing = false
						return false
					}

					let result = null
					result = await this.setChangeTiers(this.invoicedetails_id, this.change_tiers.id)
					if(result != null)
					{
						this.successToast();
						this.$refs.modalChangeTiers.hide()
						this.processing = false
						
						await this.loadBons();
					}
					else{
						this.processing = false
						this.failureToast();
					}
					this.change_tiers = null
				}

			},

			async loadStats() {
                this.stats_loaded = false
                this.stat = []

                if(this.bons.length === 0) {
                    this.stats_loaded = true
                    return false
                }

				const invoicedetails_ids = this.bons.map(invoice_details => invoice_details.invoicedetails_id)

                const stats = await this.loadInvoiceBonsStat(invoicedetails_ids)

                await Common.asyncForEach(stats, async (stat) => {
                    this.stat.push({
                        tiers: stat.tiers,
                        total_ht: await this.priceFormat(stat.total_ht, 'EUR')
                    })
                })

                this.stats_loaded = true
            },
			checkDetailsGroup() {
				// je récupère les lignes de ma sélection
				const details_cancel = this.cancel_invoicedetails_ids.split(',')
				let details = this.bons.filter(b => details_cancel.includes(b.invoicedetails_id.toString()))
				let group = {}
				details.forEach(detail => {
					if(!Object.prototype.hasOwnProperty.call(group, detail.group_id)) {
						group[detail.group_id] = 0
					}
					group[detail.group_id]++
				})
				// je vérifie si dans ma sélection je n'ai pas des lignes partielles (ex: pension)
				this.message_partiel = false
				for(let code in group) {
					let details_code = this.bons.filter(b => code == b.group_id)
					if(details_code.length > group[code]) {
						this.message_partiel = true
						break;
					}
				}
			},
			cleanHTML(input) {
				return xss(input)
			},

			openModalEditHT(invoice_details) {
				this.$refs.modal_lot_ht.openModal(invoice_details)
			}
		},
		computed: {
			duedates: function(){
				return this.params_to_send.invoices
			}
		},
		watch: {
			'params_to_send.invoices':{
				handler(val){

					if(JSON.stringify(val).indexOf('duedates') > -1 && this.copy_duedate == null && this.recalcul_duedate) {
						this.copy_duedate = JSON.stringify(val)
					}

					if(this.copy_duedate != null) {
						if(JSON.stringify(val) != this.copy_duedate) {
							//Je passe une variable pour ne pas que cela recalcul les dates d'échéances 
							this.recalcul_duedate = false
						}
					}

					if(!this.recalcul_duedate) {
						let copy_duedate = JSON.parse(this.copy_duedate)
						for (let i = 0; i < val.length; i++) {
							if(val[i].rib && copy_duedate[i].rib && val[i].rib.checkdepositconfiguration_id != copy_duedate[i].rib.checkdepositconfiguration_id) {
								this.recalcul_duedate = true
								break;
							}
							if(val[i].rib && !copy_duedate[i].rib) {
								this.recalcul_duedate = true
								break;
							}
						}
					}
				},
				deep: true
			}
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert'),
			LigneVierge: () => import('@/components/Invoice/LigneVierge'),
			Reorganize: () => import('@/components/Invoice/Reorganize'),
			ModalSelectModelPreviewBAF: () => import('@/components/Invoice/ModalSelectModelPreviewBAF'),
			ModalAvoirCC: () => import('@/components/Invoice/ModalAvoirCC'),
			ModalEditHTLot: () => import('@/components/Invoice/ModalEditHTLot'),
			ModalBons: () => import ('../Modals/ModalBons.vue')
		}
	}
</script>
