
<template>
<div>
    <b-modal ref="modal" hide-footer>
        <template v-slot:modal-title v-if="!confirmationInvoice0">
            {{ $t("action.facturer") }}
        </template>
        <template v-slot:modal-title v-else>
            {{ $t("action.confirmation") }}
        </template>

        <div v-if="!modal_loaded">
            <LoadingSpinner class="col-12" />
        </div>

        <div v-if="modal_loaded && !confirmationInvoice0">
            <form @submit.prevent="checkIfFirstInvoiceMonth" class="mb-4">
                <div class="row">
                    <div class="col-12" v-if="form_message !== ''">
                        <ErrorAlert :messageI18n="form_message" />
                    </div>

                    <div class="col-12">
                        <div class="form-group" v-if="version === 1" >
                            <label for="date">{{ $t('invoice.definir_date_facturation') }}</label>
                            <e-datepicker v-model="date"></e-datepicker>
                            <small class="form-text text-muted">{{ $t('invoice.definir_date_facturation_text') }}</small>
                        </div>

                        <div class="form-group" v-if="date > today && can_display_renew && version === 1">
                            <label for="date_automatique">{{ $t('invoice.renouvellement_facture') }}</label>
                            <e-select
                                v-model="renew"
                                id="code"
                                track-by="label"
                                label="label"
                                :placeholder="labelTitleTemplateRenew"
                                :deselectLabel="deselectLabel"
                                :selectedLabel="selectedLabel"
                                :options="renew_options"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                            >
                            </e-select>
                        </div>

                        <div class="row">
                            <div class="col-6 mb-2">
                                <label class="mb-1" for="articles_ht">{{ $t('invoice.ref_client') }}</label>
                                <input type="text" class="form-control" v-model="ref_client">
                            </div>
                            <div class="col-6 mb-2">
                                <label class="mb-1" for="articles_ht">{{ $t('invoice.dossier_interne') }}</label>
                                <input type="text" class="form-control" v-model="dossier_interne">
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <b-form-checkbox
                                        v-model="send_email"
                                        :unchecked-value="false"
                                    >
                                    {{ $t('invoice.send_invoice_by_email') }} <font-awesome-icon v-if="isLoadingTemplate" :icon="['fal', 'spinner']" pulse />
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <b-form-checkbox
                                        v-model="many_duedate"
                                        >
                                        {{ $t('invoice.multi_echeance') }}
                                    </b-form-checkbox>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <b-form-checkbox
                                        v-model="change_model"
                                        >
                                        {{ $t('invoice.changer_modele_facture') }} <font-awesome-icon v-if="isLoadingTemplate" :icon="['fal', 'spinner']" pulse />
                                    </b-form-checkbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <b-form-checkbox
                                        v-model="change_rib"
                                        >
                                        {{ $t('invoice.changer_rib_default') }}
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template v-if="version === 3">
                    <template v-if="change_model_ready">
                        <div class="row" v-for="(author, author_key) in authors" :key="'model_' + author_key">
                            <div class="col">
                                <div class="form-group">
                                    <label for="">{{ $t('invoice.choisir_un_modele_specifique_pour') }} {{ authors_name[author_key] }}</label>
                                    <e-select
                                        v-model="model_choosed[author]"
                                        id="search_compte"
                                        track-by="model_label"
                                        label="model_label"
                                        :placeholder="getTrad('invoice.choisir_un_modele_specifique')"
                                        :selectedLabel="getTrad('global.selected_label')"
                                        :options="model[author]"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :loading="isLoadingModel"
                                        :show-labels="false"
                                    >
                                    </e-select>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="send_email_ready">
                        <div class="row" v-for="(author, author_key_) in authors" :key="'mail' + author_key_">
                            <div class="col">
                                <div class="form-group">
                                    <label for="modeltype_label">{{ $t('invoice.modeltype_label') }} {{ authors_name[author_key_] }}</label>
                                    <div v-if="tiers_mail[author_key_] && tiers_mail[author_key_] != ''">
                                        <p>{{ $t('global.destinataires') }}: 
                                            <span>{{ tiers_mail[author_key_] }}</span> 
                                        </p>
                                    </div>
                                    <e-select
                                        v-if="show_model_selector"
                                        v-model="template_choosed[author]"
                                        id="model_id"
                                        track-by="model_label"
                                        label="model_label"
                                        :placeholder="labelTitleTemplate"
                                        :deselectLabel="deselectLabel"
                                        :selectedLabel="selectedLabel"
                                        :options="templates[author]"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :loading="isLoadingTemplate"
                                        :show-labels="false"
                                        @input="setModel(author)"
                                    >
                                    </e-select>
                                    <b-form-checkbox
                                        v-model="send_mail_sender"
                                        :unchecked-value="false"
                                    >
                                    {{ $t('monte.envoie_mail_sender') }}
                                    </b-form-checkbox>
                                </div>
                                <div v-if="template_choosed[author] && template_choosed[author].model_id !== 0">
                                    <a href="#" @click.prevent="showModel(author)">{{ $t('model.afficher_model') }}</a>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="mb-3" v-for="(invoice, invoice_key) in params_to_send.invoices" :key="'invoice_'+invoice_key">
                        <div class="row border-top pt-3">
                            <div class="col">
                                <label for="duedate_date">{{ invoice.group_by_author_tiers }} {{ $t('invoice.invoice_balance') }} :</label>
                                <br>
                                <span> {{ amount[invoice.author_id + '_' + invoice.tiers_id].invoice_ttc_formatted }} </span>
                            </div>
                        </div>
                        <div class="row" v-show="many_duedate">
                            <div class="col">
                                <div class="form-group">
                                    <template v-for="(duedate, duedate_key) in invoice.duedates" >
                                        <div class="row" :key="'duedate_'+duedate_key">
                                            <div class="col">
                                                <e-datepicker v-model="duedate.date" :class="{ 'is-invalid': errors && errors.indexOf('Erreur date duedate ' + duedate.date) > -1 }"></e-datepicker>
                                            </div>
                                            <div class="col">
                                                <input class="form-control" type="text" v-model="duedate.amount" @change="calculDuedateAmount" :class="{ 'is-invalid': errors && errors.indexOf('Erreur somme duedate ' + duedate.amount) > -1 }">
                                            </div>
                                        </div>
                                    </template>
                                    <a href="#" @click.prevent="add_duedate(invoice)">{{ $t('global.ajouter') }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="change_rib_ready">
                            <div class="col">
                                <div class="form-group">
                                    <label for="">{{ $t('invoice.choisir_un_rib_specifique') }}</label>
                                    <e-select
                                        v-model="invoice.rib"
                                        id="search_compte"
                                        track-by="checkdepositconfiguration_id"
                                        label="checkdepositconfiguration_iban"
                                        :placeholder="getTrad('invoice.choisir_un_rib_specifique')"
                                        :selectedLabel="getTrad('global.selected_label')"
                                        :options="ribs[invoice.author_id]"
                                        :searchable="true"
                                        :allow-empty="true"
                                        :loading="isLoadingRib"
                                        :show-labels="false"
                                    >
                                        <template slot="singleLabel" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
                                        <template slot="option" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
                                    </e-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <div class="mb-3" v-for="(invoice, invoice_key) in params_to_send.invoices" :key="'invoice_'+invoice_key">
                        <div class="row border-top pt-3">
                            <div class="col">
                                <label for="duedate_date">{{ invoice.group_by_author_tiers }} {{ $t('invoice.invoice_balance') }} :</label>
                                <br>
                                <span> {{ amount[invoice.author_id + '_' + invoice.tiers_id].invoice_ttc_formatted }} </span>
                            </div>
                        </div>
                        <div class="row" v-show="many_duedate">
                            <div class="col">
                                <div class="form-group">
                                    <template v-for="(duedate, duedate_key) in invoice.duedates" >
                                        <div class="row" :key="'duedate_'+duedate_key">
                                            <div class="col">
                                                <e-datepicker v-model="duedate.date" :class="{ 'is-invalid': errors && errors.indexOf('Erreur date duedate ' + duedate.date) > -1 }"></e-datepicker>
                                            </div>
                                            <div class="col">
                                                <input class="form-control" type="text" v-model="duedate.amount"  @change="calculDuedateAmount" :class="{ 'is-invalid': errors && errors.indexOf('Erreur somme duedate ' + duedate.amount) > -1 }">
                                            </div>
                                        </div>
                                    </template>
                                    <a href="#" @click.prevent="add_duedate(invoice)">{{ $t('global.ajouter') }}</a>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="send_email_ready">
                            <div class="col">
                                <div class="form-group">
                                    <label for="modeltype_label">{{ $t('model.modeltype_label') }}</label>
                                    <div v-if="tiers_mails && tiers_mails != ''">
                                        <p>{{ $t('global.destinataires') }}: {{ tiers_mails }}</p>
                                    </div>
                                    <e-select
                                        v-if="show_model_selector"
                                        v-model="invoice.template"
                                        id="model_id"
                                        track-by="model_label"
                                        label="model_label"
                                        :placeholder="labelTitleTemplate"
                                        :deselectLabel="deselectLabel"
                                        :selectedLabel="selectedLabel"
                                        :options="templates[invoice.author_id]"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                    >
                                        <template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
                                        <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                    </e-select>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="change_model_ready">
                            <div class="col">
                                <div class="form-group">
                                    <label for="">{{ $t('invoice.choisir_un_modele_specifique') }}</label>
                                    <e-select
                                        v-model="invoice.model"
                                        id="search_compte"
                                        track-by="model_label"
                                        label="model_label"
                                        :placeholder="getTrad('invoice.choisir_un_modele_specifique')"
                                        :selectedLabel="getTrad('global.selected_label')"
                                        :options="model[invoice.author_id]"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :loading="isLoadingModel"
                                        :show-labels="false"
                                    >
                                    </e-select>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="change_rib_ready">
                            <div class="col">
                                <div class="form-group">
                                    <label for="">{{ $t('invoice.choisir_un_rib_specifique') }}</label>
                                    <e-select
                                        v-model="invoice.rib"
                                        id="search_compte"
                                        track-by="checkdepositconfiguration_id"
                                        label="checkdepositconfiguration_iban"
                                        :placeholder="getTrad('invoice.choisir_un_rib_specifique')"
                                        :selectedLabel="getTrad('global.selected_label')"
                                        :options="ribs[invoice.author_id]"
                                        :searchable="true"
                                        :allow-empty="true"
                                        :loading="isLoadingRib"
                                        :show-labels="false"
                                    >
                                        <template slot="singleLabel" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
                                        <template slot="option" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
                                    </e-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="form-group mt-2" v-if="version !== 1">
                    <div class="row mx-0">
                        <label for="date">{{ $t('invoice.definir_date_facturation') }}</label>
                        <small v-if="last_invoice_date" class="mt-1 ml-2">{{ $t('invoice.date_derniere_facturation') }}: <a href="#" style="color:red;" @click="assignDate(last_invoice_date.invoice_date)">{{ last_invoice_date.formated_date }}</a></small>
                    </div>
                    <e-datepicker v-model="date"></e-datepicker>
                    <div v-if="accounting_closed" class="mt-2 alert alert-danger">{{ $t('invoice.accounting_closed') }} <router-link :to="{ name: 'AccountingInformations', params: { accountingplan_id: accounting_closed }}">{{ $t('support.cliquant_ici') }}</router-link></div>
                    <small class="form-text text-muted">{{ $t('invoice.definir_date_facturation_text') }}</small>
                </div>
                <div class="form-group" v-if="date > today && can_display_renew">
                    <label for="date_automatique">{{ $t('invoice.renouvellement_facture') }}</label>
                    <e-select
                        v-model="renew"
                        id="code"
                        track-by="label"
                        label="label"
                        :placeholder="labelTitleTemplateRenew"
                        :deselectLabel="deselectLabel"
                        :selectedLabel="selectedLabel"
                        :options="renew_options"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                    >
                    </e-select>
                </div>
            </form>
            <div v-if="show_model" class="col-12 col-lg-6">
                <div v-if="model_selected" class="border-2 px-3 py-3" v-html="model_selected"></div>
                <LoadingSpinner v-else class="col-12" />
            </div>

            <div class="col-8 m-auto">
                <b-button :disabled="accounting_closed !== false"  block pill variant="primary" @click="checkIfFirstInvoiceMonth"><font-awesome-icon v-if="processing || processing_check" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </div>
        <div v-if="modal_loaded && confirmationInvoice0">
            <h3>{{ $t("invoice.ttc_0_confirmation_1") }} {{ actual_price_and_currency }}</h3> 
            <h3>{{ $t("invoice.ttc_0_confirmation_2") }}</h3>
            <b-button class="mt-3 rounded-pill" block @click.prevent="continueInvoice0" variant="primary">{{ $t("global.continuer") }}</b-button>
            <b-button class="mt-3 rounded-pill" block @click.prevent="cancelInvoice0" variant="primary">{{ $t("global.annuler") }}</b-button>
        </div>
    </b-modal>

    <b-modal ref="confirmation_first_invoice" hide-footer>
        <template v-slot:modal-title>
            {{ $t("invoice.title_first_invoice_of_month") }}
        </template>
        <div class="row justify-content-around">
            <div class="col">
                {{ $t("invoice.first_invoice_of_month") }}
                
                <b-button class="mt-3 rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing_check" :icon="['fas', 'spinner']" pulse /> {{ $t("global.continuer") }}</b-button>
                <b-button class="mt-3 rounded-pill" block @click.prevent="closeFirstInvoiceModal" variant="primary">{{ $t("global.annuler") }}</b-button>
            </div>
        </div>
    </b-modal>
</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'
	import _uniq from 'lodash/uniq'
	import Invoice from "@/mixins/Invoice.js"
	import Tiers from "@/mixins/Tiers.js"
	import Model from "@/mixins/Model.js"
    import { isArray } from 'lodash'
	import Contract from "@/mixins/Contract.js"

	export default {
		name: 'ModalBons',
        mixins: [Invoice, Tiers, Model, Contract],
		props: ['version', 'bons', 'authors_data', 'stay'],
        data() {
            return {
                confirmationInvoice0: false,
                modal_loaded: false,
                form_message: '',
				date: new Date(),
				today: new Date(),
				can_display_renew: false,
				labelTitleTemplateRenew: this.getTrad("invoice.search_renew"),
				selectedLabel: this.getTrad("global.selected_label"),
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				renew_options: [
					{
						"code": 'null',
						"label": this.getTrad("invoice.renew.no")
					},
					{
						"code": 'mensuel',
						"label": this.getTrad("invoice.renew.mensuel")
					},
					{
						"code": 'annuel',
						"label": this.getTrad("invoice.renew.annuel")
					},
				],
                ref_client: null,
				dossier_interne: null,
				send_email: false,
				isLoadingTemplate: false,
				many_duedate: false,
				change_model: false,
				change_model_ready: false,
				change_rib: false,
                change_rib_ready: false,
				model_choosed : {},
				isLoadingModel: false,
                authors_name: [],
                send_email_ready: false,
				show_model_selector: true,
				template_choosed : {},
				labelTitleTemplate: this.getTrad("horse.search_doc"),
				templates: [],
				send_mail_sender: false,
				params_to_send : [],
                invoice: {},
                amount : {},
				last_invoice_date: {},
                accounting_closed: false,
				actual_price_and_currency: null,
                details_selected: [],
                processing_check: false,
				processing: false,
				errors : [],
				model: [],
				ribs: [],
				copy_duedate: null,
				authors_accounting: {},
				isLoadingRib: false,
                ribs_from_stallions: {},
                use_rib_stallions: true
            }

        },
		methods: {
            async openModal(invoicedetails_ids, authors_calc = null) {
                				
				this.modal_loaded = false
				this.show_model = false
				this.model_selected = false
				this.send_email = false
				this.confirmationInvoice0 = false
                this.authors = authors_calc ?? this.authors_data ?? []
				this.$refs['modal'].show()
				

				this.details_selected = this.bons.filter(bons => invoicedetails_ids.includes(bons.invoicedetails_id))
				this.can_display_renew = false

				this.ref_client = null
				this.dossier_interne = null

				const splited_invoice_details = invoicedetails_ids.split(",")
				const all_bons = this.bons
				const infos = []
                const author_tiers = []
                this.tiers_tab = []
                this.authors_name = []
                this.tiers_mail = []
                this.model_choosed = {}
                this.template_choosed = {}
				const refs_client = []
				const dossiers_interne = []
                const authors = []
				let nb_ligne_vierge = 0
				this.ribs_from_stallions = {}

				this.date = new Date()
				this.renew = {
					"code": 'null',
					"label": this.getTrad("invoice.renew.no")
				}
                
				for (var i = 0; i < splited_invoice_details.length; i++) {
                    const bons_selected = this.bons.filter(all_bons => all_bons.invoicedetails_id == splited_invoice_details[i])[0]
					
					if(bons_selected.invoicedetails_actes == null && bons_selected.invoicedetails_contract == null && bons_selected.invoicedetails_horsepensionperiode == null) {
						nb_ligne_vierge++
					}

					if(refs_client.indexOf(bons_selected.invoicedetails_ref_client) == -1) {
						refs_client.push(bons_selected.invoicedetails_ref_client)
					}

					if(this.authors.indexOf(bons_selected.invoicedetails_author) == -1) {
						this.authors.push(bons_selected.invoicedetails_author)
						this.authors_name.push(bons_selected.group_by_author_tiers.substring(0, bons_selected.group_by_author_tiers.indexOf("/")))
						let tiers_contact = await this.loadTiersContacts(bons_selected.tiers.tiers_id)
						let tiers_mails = [bons_selected.tiers.tiers_mail]
						// On ne garde que les contacts ayant la fonction comptabilité ET ayant un mail
						tiers_contact = tiers_contact.filter((contact) => (contact.fonctions.some(fonction => fonction.fonction_code == 'COMPTA') && contact.contact.contact_mail !== ""))
						tiers_contact.forEach((contact) => 
							tiers_mails.push(contact.contact.contact_mail)
						)
						this.tiers_mail.push([...new Set(tiers_mails)].join(", ")) // Format d'email sans doublons et séparés par une virgule
					}

					if(dossiers_interne.indexOf(bons_selected.invoicedetails_dossier_interne) == -1) {
						dossiers_interne.push(bons_selected.invoicedetails_dossier_interne)
					}

					if(author_tiers.indexOf(bons_selected.invoicedetails_author + "_" + bons_selected.invoicedetails_tiers) === -1) {
						infos.push({
							"author_id": bons_selected.invoicedetails_author,
							"author_currency": bons_selected.author.tiers_currency,
							"tiers_id": bons_selected.invoicedetails_tiers,
							"group_by_author_tiers": bons_selected.group_by_author_tiers
						})

						this.tiers_tab.push(bons_selected.tiers)
						author_tiers.push(bons_selected.invoicedetails_author + "_" + bons_selected.invoicedetails_tiers)
						if(this.version !== 3){
							let tiers_contact = await this.loadTiersContacts(bons_selected.tiers.tiers_id)
							let tiers_mails = [bons_selected.tiers.tiers_mail]
							// On ne garde que les contacts ayant la fonction comptabilité ET ayant un mail
							tiers_contact = tiers_contact.filter((contact) => (contact.fonctions.some(fonction => fonction.fonction_code == 'COMPTA') && contact.contact.contact_mail !== ""))
							tiers_contact.forEach((contact) => 
								tiers_mails.push(contact.contact.contact_mail)
							)
							this.tiers_mails = ([...new Set(tiers_mails)].join(", ")) // Format d'email sans doublons et séparés par une virgule
						}

					}

					if(bons_selected.contract && bons_selected.contract.contract_config && bons_selected.contract.contract_config.contractconfig_configuration != null) {
						if(!this.ribs_from_stallions[bons_selected.group_by_author_tiers]) {
							this.ribs_from_stallions[bons_selected.group_by_author_tiers] = []
						}

						this.ribs_from_stallions[bons_selected.group_by_author_tiers].push(bons_selected.contract.contract_config.contractconfig_configuration)
					}
					else{
						this.use_rib_stallions = false
					}
				}
								
				if(refs_client.length == 1) {
					this.ref_client = refs_client[0]
				}

				if(dossiers_interne.length == 1) {
					this.dossier_interne = dossiers_interne[0]
				}

				if(nb_ligne_vierge == splited_invoice_details.length) {
					this.can_display_renew = true
				}

				this.params_to_send = {
					"invoices": infos,
					"invoicedetails_ids": invoicedetails_ids
                }

                const tiers_ids = this.tiers_tab.map(tier => tier.tiers_id).join(",")
                const data = await this.prepareCalculInvoice(this.bons[0].author.tiers_id, tiers_ids, this.params_to_send)

                this.last_invoice_date = data.last_date
                if(this.last_invoice_date && this.last_invoice_date.invoice_date) {
                    this.last_invoice_date.invoice_date = new Date(this.last_invoice_date.invoice_date)
                    this.last_invoice_date.formated_date = this.formatDate(this.last_invoice_date.invoice_date)
                }
                this.config_duedate = data.config_duedate
                this.amount = data.amount

				let a = Object.keys(this.amount)[0]
				const numeric_ttc = parseFloat(this.amount[a].invoice_ttc.replace(',', '.'))
				const numeric_ht = parseFloat(this.amount[a].invoice_ht.replace(',', '.'))
				if (numeric_ttc === 0 && numeric_ht === 0){
					this.actual_price_and_currency = await this.priceFormat(numeric_ttc, this.params_to_send.invoices[0].author_currency, true)
					this.confirmationInvoice0 = true
				}

				await this.add_duedate()
				this.date = new Date()

				this.modal_loaded = true

            },
            confirm() {
            	this.$refs.modal.hide()
            	this.callback_confirm()
            },
            cancel() {
            	this.$refs.modal.hide()
            },
            calculDuedateAmount() {
				for(let i in this.params_to_send.invoices) {
					for(let j in this.params_to_send.invoices[i].duedates) {
						this.params_to_send.invoices[i].duedates[j].amount_float = this.params_to_send.invoices[i].duedates[j].amount*100
					}
				}
			},
            assignDate(date) {
				this.date = date
			},
            checkIfFirstInvoiceMonth: async function() {
				this.processing_check = true
				const elems = await this.checkFirstInvoiceMonth(this.date.toDateInputValue(), this.params_to_send.invoicedetails_ids)
				
				this.processing_check = false
				if(elems.nb == 0) {
					this.$refs['confirmation_first_invoice'].show()
				}
				else {
					await this.checkForm()
				}
			},
            async setModel(author) {
            	this.show_model_selector = false
            	this.$nextTick(function () {
            		this.show_model_selector = true
            	})
            	this.model_selected = false
        		if(this.template_choosed[author] && this.template_choosed[author].model_id) {
        			const models = await this.loadModel(this.authors_accounting[author], this.template_choosed[author].model_id, 'accounting_plan')
					const templates = await this.loadTemplate(this.authors_accounting[author], this.template_choosed[author].model_id, 'accounting_plan',  Vue.i18n.locale())
					const zone = models[0].zone.find(zone => zone.zonetype.modelzonetype_code == 'invoice_notification_body')
					this.model_selected = templates.find(temp => temp.modeltemplate_modelzone == zone.modelzone_id).modeltemplate_content
        		}
            },
            showModel(author) {
				this.setModel(author)
				this.show_model = true
				let modal = document.getElementsByClassName('modal-dialog')[0]
				modal.classList.add('modal-xxl')
			},
            async load_model(){

                this.change_model_ready = false
                this.isLoadingModel = true
                for (let i = 0; i < this.authors.length; i++) {
                    let result = await this.loadAccountingPlanByEntity(this.authors[i])

                    let models = await this.loadAllModel(result.accountingplan_id, ['accounting_plan'])
                    models = models.filter(model => (model.type.modeltype_template === 'facture'))

                    this.model[this.authors[i]] = models
                }

                this.change_model_ready = true
                this.isLoadingModel = false
            },
            async add_duedate(tab_invoice){
				if(tab_invoice != undefined && !isArray(this.params_to_send))
				{
					for (let i = 0; i < this.params_to_send.invoices.length; i++)
					{
						//J'ajoute une date là ou j'ai choisi de cliquer sur ajouter
						if(this.params_to_send.invoices[i].group_by_author_tiers == tab_invoice.group_by_author_tiers)
						{
							let current = new Date(this.date);
							const key = this.params_to_send.invoices[i].author_id + "_" + this.params_to_send.invoices[i].tiers_id

							this.params_to_send.invoices[i].duedates.push({
								'date': undefined,
								'amount': "0",
								'amount_float': 0,
								'total_invoice': Math.round(this.amount[key].invoice_ttc.toString().toFloat() * 100)
							})
						}
					}
					this.calculDuedateDate(true)
				}
				else
				{
					this.calculDuedateDate()
				}

			},
            async calculDuedateDate(use_prev = false){

                this.new_invoices_tab = []
                if(isArray(this.params_to_send)) return
                for (let i = 0; i < this.params_to_send.invoices.length; i++) {
                    let author_id = this.params_to_send.invoices[i].author_id
                    let tiers_id = this.params_to_send.invoices[i].tiers_id
                    let key = author_id + "_" + tiers_id

                    let current_date = this.date

                    //Je regarde si je dois prendre la date précédente pour calculer les échéances à venir
                    if(use_prev)
                    {
                        if(this.params_to_send.invoices[i].duedates != undefined)
                        {
                            let all_amount = parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)

                            //Je parcours les echeances pour calculer la date
                            for (let j = 0; j < this.params_to_send.invoices[i].duedates.length; j++)
                            {

                                let date = null
                                let current = null
                                let amount_float = 0

                                if(j == 0)
                                {
                                    if(this.params_to_send.invoices[i].duedates[j] != undefined)
                                    {
                                        current = this.params_to_send.invoices[i].duedates[j].date
                                    }
                                    else
                                    {
                                        current = this.calculNextDateDuedate(this.config_duedate[tiers_id], current_date)
                                    }
                                }
                                else
                                {
                                    if(this.params_to_send.invoices[i].duedates[j].date != undefined)
                                    {
                                        current = this.params_to_send.invoices[i].duedates[j].date
                                    }
                                    else
                                    {
                                        current = this.calculNextDateDuedate(this.config_duedate[tiers_id], this.params_to_send.invoices[i].duedates[j - 1].date)
                                    }
                                }

                                //Si j'ai une valeur de renseignée, je la reprend
                                if(this.params_to_send.invoices[i].duedates[j].amount != undefined)
                                {
                                    amount_float = parseInt(this.params_to_send.invoices[i].duedates[j].amount.toString().toFloat() * 100)
                                }

                                if((j + 1) == this.params_to_send.invoices[i].duedates.length)
                                {
                                    amount_float = all_amount
                                }

                                this.params_to_send.invoices[i].duedates[j] = {
                                    'date': current,
                                    'amount': (amount_float / 100).toString().toFloat(),
                                    'amount_float': amount_float,
                                    'total_invoice': parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
                                }
                                all_amount -= amount_float

                                if(all_amount < 0)
                                {
                                    all_amount = 0
                                }
                            }
                        }
                    }
                    else
                    {
                        let date = this.calculNextDateDuedate(this.config_duedate[tiers_id], current_date)

                        let current = new Date(date);
                        let amount_float = parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
                        this.params_to_send.invoices[i].duedates = [{
                            'date': current,
                            'amount': this.amount[key].invoice_ttc,
                            'amount_float': amount_float,
                            'total_invoice': parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
                        }]
                    }
                    this.new_invoices_tab.push(this.params_to_send.invoices[i])
                }

                this.$set(this.params_to_send, 'invoices', this.new_invoices_tab)
            },
            async checkDateCloture() {
				const details_author = _uniq(this.details_selected.map(details => details.author.tiers_id))
				this.accounting_closed = false
				for(let i in details_author) {
					let accouting_plan = await this.loadAccountingPlanByEntity(details_author)
					if(accouting_plan.accountingplan_closing_date < this.date) {
						this.accounting_closed = accouting_plan.accountingplan_id
						break
					}
				}
			},
            async load_model_notification(){

                this.send_email_ready = false
                this.isLoadingTemplate = true

                if(this.version === 3){
                    for (let i = 0; i < this.authors.length; i++) {
                        let result = await this.loadAccountingPlanByEntity(this.authors[i])
                        this.authors_accounting[this.authors[i]] = result.accountingplan_id
                        const templates = await this.loadAllModel(result.accountingplan_id, ['accounting_plan'], 'invoice_notification')

                        const model_default = {
                            model_default: false,
                            model_id: 0,
                            model_label: this.getTrad('invoice.model_mail_default'),
                        }

                        templates.push(model_default)
                        this.templates[this.authors[i]] = templates
                        this.template_choosed[this.authors[i]] = model_default
                    }
                } else if (!isArray(this.params_to_send)) {
                    for (let i = 0; i < this.params_to_send.invoices.length; i++) {
                        let result = await this.loadAccountingPlanByEntity(this.params_to_send.invoices[i].author_id)
                        const templates = await this.loadAllModel(result.accountingplan_id, ['accounting_plan'], 'invoice_notification')

                        const model_default = {
                            model_default: false,
                            model_id: 0,
                            model_label: this.getTrad('invoice.model_mail_default'),
                        }

                        templates.push(model_default)
                        this.templates[this.params_to_send.invoices[i].author_id] = templates
                        this.params_to_send.invoices[i].template = model_default
                    }
                }

                this.send_email_ready = true
                this.isLoadingTemplate = false

            },
            checkForm: async function(){
				
				this.$refs['confirmation_first_invoice'].hide()

				if(!this.processing)
				{
					this.processing = true
					this.errors = []

					if(this.date == null)
					{
						this.errors.push("Erreur date null")
					}

					if(!Date.parse(this.date))
					{
						this.errors.push("Erreur wrong date")
					}
					//Je vais regarder que mes dates d'échéances soient supérieurs ou égales à ma date de facturation, et regarder le montant
					for (let i = 0; i < this.params_to_send.invoices.length; i++)
					{
						let somme = 0

                        if(this.change_model && ((this.version == 3 && this.model_choosed[this.params_to_send.invoices[i].author_id] == undefined) || (this.version !== 3 && this.params_to_send.invoices[i].model == undefined))) {
                            this.errors.push("Erreur model")
                        }

                        if(this.change_rib && (this.params_to_send.invoices[i].rib == undefined || this.params_to_send.invoices[i].rib == null)) {
                            this.errors.push("Erreur compte bancaire (rib)")
                        }

                        //Si j'ai qu'un seul rib etalon de selectionné pour une entité/tiers, et que je n'ai pas choisi manuellement mon rib
                        if(this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers] != undefined
                            && _uniq(this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers]).length == 1
                            && (this.params_to_send.invoices[i].rib == undefined || this.params_to_send.invoices[i].rib == null)
                            && this.use_rib_stallions
                        ) {
                            this.params_to_send.invoices[i].rib = {
                                "checkdepositconfiguration_id": this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers][0]
                            }
                        }

                        if(this.model_choosed[this.params_to_send.invoices[i].author_id] != undefined) {
                            this.params_to_send.invoices[i].model = this.model_choosed[this.params_to_send.invoices[i].author_id]
                        }


						for (let j = 0; j < this.params_to_send.invoices[i].duedates.length; j++)
						{
							if(j == 0)
							{
								somme = this.params_to_send.invoices[i].duedates[j].total_invoice
							}

							if(!Date.parse(this.params_to_send.invoices[i].duedates[j].date) || this.params_to_send.invoices[i].duedates[j].date < this.date)
							{
								this.errors.push("Erreur date duedate " + this.params_to_send.invoices[i].duedates[j].date)
							}

							somme -= parseInt(this.params_to_send.invoices[i].duedates[j].amount.toString().toFloat() * 100)
						}
						if(somme != 0)
						{
							this.errors.push("Erreur somme duedate " + this.params_to_send.invoices[i].duedates[0].amount)
						}
                    }

					if(this.errors.length == 0)
					{
						this.params_to_send.date = this.date;
                        this.params_to_send.send_mail = this.send_email
						this.params_to_send.send_mail_sender = this.send_mail_sender

						let renew = null
						if(this.date > this.today) {
							if(this.renew.code != 'null') {
								renew = this.renew.code
							}
						}

						this.params_to_send.renew = renew
						this.params_to_send.ref_client = this.ref_client
						this.params_to_send.dossier_interne = this.dossier_interne

						const result = await this.createInvoice(this.params_to_send);
						if(result) {
							const details_contract = this.details_selected.filter(details => details.invoicedetails_contract)
							const contract_ids = _uniq(details_contract.map(details => details.invoicedetails_contract))
		                    await this.$sync.force(true)
		                	ContractCleaner.inst().onMutation(contract_ids, ['articles'])
		                	this.updateContract(contract_ids)
							this.$refs['modal'].hide()
                            if(!this.stay){
                                this.$router.push({name: 'invoiceList'})
                            } else {
                                this.$emit('submit')
                            }
						} else {
							this.form_message = "error.LEP"
						}
					}
					else
					{
						this.form_message = "formulaire.erreur_champs_non_remplis"
					}

					this.processing = false
				}

			},
			closeFirstInvoiceModal: async function(){
				this.$refs['confirmation_first_invoice'].hide()
			},
            continueInvoice0(){
				this.confirmationInvoice0 = false
				return 
			},
            cancelInvoice0(){
				this.$refs['modal'].hide()
				return
			},
            clear_rib() {
				for(let i in this.params_to_send.invoices) {
					this.params_to_send.invoices[i].rib = undefined
				}
			},
            async load_rib(){
				this.change_rib_ready = false
				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let result = await this.loadAccountingPlanByEntity(this.params_to_send.invoices[i].author_id)

					let ribs = await this.getCheckDepositConfigListe(result.accountingplan_id)

					this.ribs[this.params_to_send.invoices[i].author_id] = ribs
				}

				this.change_rib_ready = true
			},
        },
        watch: {
			date(val, prev)
			{
				this.date.setHours(0)
				this.date.setMinutes(0)
				this.date.setSeconds(0)
				if(this.recalcul_duedate)
				{
					this.add_duedate()
					this.copy_duedate = null
				}
				this.calculDuedateDate()
				this.checkDateCloture()
			},
			'params_to_send.invoices':{
				handler(val){

					if(JSON.stringify(val).indexOf('duedates') > -1 && this.copy_duedate == null && this.recalcul_duedate)
					{
						this.copy_duedate = JSON.stringify(val)
					}

					if(this.copy_duedate != null)
					{
						if(JSON.stringify(val) != this.copy_duedate)
						{
							//Je passe une variable pour ne pas que cela recalcul les dates d'échéances 
							this.recalcul_duedate = false
						}

                        if(!this.recalcul_duedate && this.version !== 1) {
                            let copy_duedate = JSON.parse(this.copy_duedate)
                            for (let i = 0; i < val.length; i++) {
                                if(val[i].rib && copy_duedate[i].rib && val[i].rib.checkdepositconfiguration_id != copy_duedate[i].rib.checkdepositconfiguration_id) {
                                    this.recalcul_duedate = true
                                    break;
                                }
                                if(val[i].rib && !copy_duedate[i].rib) {
                                    this.recalcul_duedate = true
                                    break;
                                }
                            }
                        }
                    }


				},
				deep: true
			},
			many_duedate(val, prev){
				if(val === false) {
					this.add_duedate()
					this.copy_duedate = null
				}
				else {
					this.calculDuedateDate(true)
				}
			},
			change_model(val, prev){
				if(val === true) {
					this.load_model()
				}
				else{
					this.change_model_ready = false
					this.model_choosed = {}
				}

			},
			send_email(val, prev){
				if(val === true) {

					// On vérifie que les tiers sont notifiables
					const tiers_unotifiable = this.tiers_tab.filter(tier => {
						return !tier.tiers_mail
					})

					if(tiers_unotifiable.length === 1) {
						this.infoToast("invoice.tier_non_notifiable", [tiers_unotifiable[0].tiers_rs])
					}
					else if(tiers_unotifiable.length > 1) {
						const delimiter = ', '
						let tiers_rs = tiers_unotifiable.map(tier => tier.tiers_rs).join(delimiter)
						const index = tiers_rs.lastIndexOf(delimiter)
						tiers_rs = tiers_rs.substring(0, index) + ' '+this.getTrad('global.et')+' ' + tiers_rs.substring(index + delimiter.length)

						this.infoToast("invoice.tiers_non_notifiable", [tiers_rs])
					}

					this.send_email_ready = true
					this.load_model_notification()
				}
				else{
					this.send_email_ready = false
					this.template_choosed = {}
				}
			},
			change_rib(val, prev){
				if(val === true) {
					this.load_rib()
				}
				else{
					this.change_rib_ready = false
					this.clear_rib()
				}
			},
			today() {
				this.today.setHours(0)
				this.today.setMinutes(0)
				this.today.setSeconds(0)
			}
		},
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),

        }
	}

</script>